
const domain = window.location.hostname === 'localhost'
  ? 'http://mika.devindex.ru'
  : window.location.protocol+'//'+window.location.hostname;

const URL_CHECK = domain+'/check.php';
const URL_CITY = domain+'/city.php';

function getCities(){
  return fetch( new Request(URL_CITY)).then(resp => resp.json());
}


function saveCity(code){
  const formData = new FormData();
  formData.append('city', code);

  const req = new Request(URL_CITY, {
    method: 'post',
    body: formData
  });

  return fetch(req).then(resp => resp.json());
}

function check(code){
  const req = new Request(URL_CHECK, {
    method: 'post',
    body: JSON.stringify({code})
  });

  return fetch(req)
    .then(resp => {
      return resp.json();
    })
    .then(res => {
      if (!res || !res.status){
        throw 'Invalid response';
      }
      return res;
    });
}



const API = {
  getCities,
  saveCity,
  check,
};

export default API;
