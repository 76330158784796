import API from './api';

export default () => {
  const checkForm = document.querySelector('.js-check-form')

  if (!checkForm) {
    return;
  }

  const checkInput = document.querySelector('.js-check-input');
  const checkReset = document.querySelector('.js-check-reset');
  const codeLabel  = document.querySelector('.js-send-code');


  checkForm.addEventListener('submit', (e) => {
    e.preventDefault();
    if (checkInput.value.length < 7 || checkInput.value.lenth > 7) {
      checkInput.classList.add('error');
    } else {
      checkCode(checkInput.value);
    }
  })

  function checkCode(code) {
    checkForm.classList.add('send');
    codeLabel.textContent = checkInput.value;
    API.check(code)
      .then(res => {
        switch (res.status) {
          case 'success':
          case 'check':
             showSuccess();
             break;
          case 'fail':
             showFail();
             break;
          default:
            showFatal();
            break;
        }
      })
      .catch(showFatal);
  }


  function showSuccess() {
    checkForm.classList.remove('send');
    checkForm.classList.add('success');

  }

  function showFail() {
    checkForm.classList.remove('send');
    checkForm.classList.add('error');
  }

  function showFatal() {
    checkForm.classList.remove('send');
    checkForm.classList.add('fatal');
  }

  // Form reset
  checkReset.addEventListener('click', () => {
    checkForm.classList.remove('send');
    checkForm.classList.remove('success');
    checkForm.classList.remove('error');
    checkForm.classList.remove('fatal');
    checkForm.reset();
  })
}
