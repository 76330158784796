import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);

export default () => {
    const blogContainer = document.querySelector('.js-blog-slider');

    if (!blogContainer) {
        return;
    }

    const blogSlider = new Swiper(blogContainer, {
        slidesPerView: 'auto',
        navigation: {
            prevEl: '.js-blog-btn-back',
            nextEl: '.js-blog-btn-fwd',
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
        },
    })
}