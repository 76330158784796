export default () => {
    const upBtn = document.querySelector('.js-btn-up');

    if (!upBtn) {
        return;
    }

    upBtn.addEventListener('click', () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    })
}