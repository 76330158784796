import 'focus-visible';
import header from './modules/header';
import blog from './modules/blog';
import check from './modules/check';
import products from './modules/products';
import up from './modules/up';
import cities from './modules/cities';

header();
blog();
check();
products();
up();
cities();