import API from './api';

export default () => {
    const cities  = document.querySelector('.js-cities');

    if (!cities) {
        return;
    }

    const citiesOpener = document.querySelector('.js-cities-open');
    const citiesCloser = document.querySelector('.js-cities-close');
    const cityBuyLinks = document.querySelectorAll('.js-buy-link');
    const body = document.querySelector('body');

    function openList() {
        cities.classList.add('active');
        body.classList.add('locked');
    }

    function closeList() {
        cities.classList.remove('active');
        body.classList.remove('locked');
    }

    citiesOpener.addEventListener('click', () => {
        openList();
    })

    citiesCloser.addEventListener('click', () => {
        closeList()
    })

    const citiesList = document.querySelectorAll('.js-city');

    if (citiesList.length === 0) {
        return;
    }

    citiesList.forEach((city) => {
        city.addEventListener('click', () => {
            API.saveCity(city.id).then((data) => {
                cityBuyLinks.forEach((link) => {
                    link.setAttribute('href', data.current.href);
                })
                citiesOpener.textContent = data.current.name;
                closeList();
            });
        })
    })
}