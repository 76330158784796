export default ()=>{
    const header = document.querySelector('.js-header');

    if (!header) {
        return;
    }

    const headerBtn = document.querySelector('.js-header-btn');
    const headerOverlay = document.querySelector('.js-header-overlay');
    const body = document.querySelector('body');

    headerBtn.addEventListener('click', () => {
        header.classList.toggle('active');
        body.classList.toggle('locked');
    })
    headerOverlay.addEventListener('click', () => {
        header.classList.remove('active');
        body.classList.remove('locked');
    })

    const anchorLinks = document.querySelectorAll('.js-anchor-link');
    const targets = document.querySelectorAll('.js-section[data-name]');

    if(anchorLinks.length === 0) {
        return;
    }

    function getTop(elem) {
        const box = elem.getBoundingClientRect();
        const boxTop = box.top + pageYOffset;
        return boxTop;
    }
    
    function onLinkClick(evt) {
        const {target} = evt;
        const item = target.closest('.js-anchor-link');
    
        if (item) {
          evt.preventDefault();
    
          const targetName = item.getAttribute('data-target');
    
            for (let t = 0; t < targets.length; t++) {
                if (targets[t].getAttribute('data-name') === targetName) {   
                    targets[t].scrollIntoView({behavior: 'smooth'});
                }
            }

            header.classList.remove('active');
            body.classList.remove('locked');
        }
    }

    anchorLinks.forEach((link) => {
        link.addEventListener('click', onLinkClick);
    })
}