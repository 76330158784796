import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);

export default () => {
    const productsContainer = document.querySelector('.js-products-slider');

    if (!productsContainer) {
        return;
    }

    if(window.matchMedia('(max-width: 1023px)').matches) {
        const productsSlider = new Swiper(productsContainer, {
            slidesPerView: 'auto',
            spaceBetween: 53,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
            },
        })
    }
}